<template>
  <div class="container">
    <div class="content">
      <section class="section inner-H">
        <div class="flow">
          <h2 class="headline">会員登録の流れ</h2>
          <img class="flow-step" src="/assets/img/eKYC/registration_flow_step1_ekyc.png" />
          <img class="flow-bar" src="/assets/img/account/flow-bar01.png" />
        </div>
      </section>
      <form class="form">
        <div class="form-sheet">
          <section class="form-section">
            <p class="form-headline">お客様情報</p>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">お名前</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <table class="form-table">
                    <tr>
                      <th>[姓]</th>
                      <td>
                        <input
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-space
                          class="textfield"
                          v-model="form.nameSei"
                          type="text"
                          placeholder="山田"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>[名]</th>
                      <td>
                        <input
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-space
                          class="textfield"
                          v-model="form.nameMei"
                          type="text"
                          placeholder="太郎"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">フリガナ</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <table class="form-table">
                    <tr>
                      <th>[セイ]</th>
                      <td>
                        <input
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-katakana
                          v-model="form.kanaSei"
                          class="textfield"
                          type="text"
                          placeholder="ヤマダ"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>[メイ]</th>
                      <td>
                        <input
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-katakana
                          v-model="form.kanaMei"
                          class="textfield"
                          type="text"
                          placeholder="タロウ"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">性別</p>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <ul class="radioGrid">
                    <li
                      class="radioGrid-item"
                      v-for="(item, index) in genders"
                      :key="index"
                    >
                      <label class="radio">
                        <input
                          class="radio-input"
                          type="radio"
                          :value="item.value"
                          name="gender"
                          v-model="form.gender"
                        />
                        <span class="radio-label">{{ item.label }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">生年月日</p>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <div class="form-group">
                    <div class="form-group-item">
                      <div class="select">
                        <select v-model="selectedYear" class="select-input">
                          <option value="" selected>- - - -</option>
                          <option v-for="(year, index) in years" :key="index">
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group-item">年</div>
                    <div class="form-group-item">
                      <div class="select">
                        <select v-model="selectedMonth" class="select-input">
                          <option value="" selected>- -</option>
                          <option v-for="(month, index) in months" :key="index">
                            {{ month }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group-item">月</div>
                    <div class="form-group-item">
                      <div class="select">
                        <select v-model="selectedDay" class="select-input">
                          <option value="" selected>- -</option>
                          <option v-for="(day, index) in days" :key="index">
                            {{ day }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group-item">日</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">郵便番号</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <input
                    v-model="form.zipcode"
                    v-number
                    class="textfield textfield-w150"
                    maxlength="7"
                    v-maxlength
                    type="tel"
                  />
                </div>
                <div class="form-content-row">
                  <p class="form-help">※ハイフンなしでご入力ください</p>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">電話番号</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <input
                    v-number
                    v-model="form.telephone"
                    class="textfield"
                    maxlength="11"
                    v-maxlength
                    type="tel"
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="form-section">
            <p class="form-headline">ログイン情報</p>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">メールアドレス</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <input
                    v-trim
                    v-maxlength
                    maxlength="50"
                    v-model="form.email"
                    class="textfield"
                    type="text"
                    placeholder="メールアドレスを入力"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">確認用メールアドレス</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <input
                    v-trim
                    v-model="form.repeatedEmail"
                    class="textfield"
                    type="text"
                    placeholder="確認用メールアドレスを入力"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">ご希望のパスワード</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <div class="form-content-row">
                  <input
                    v-model="form.password"
                    class="textfield"
                    type="password"
                  />
                </div>
                <div class="form-content-row">
                  <input
                    v-model="form.repeatedPassword"
                    class="textfield"
                    type="password"
                  />
                </div>
                <div class="form-content-row">
                  <p class="form-help">
                    ※半角英数字を組み合わせ、8～20文字で設定してください。<br />
                    また、半角記号（&#092; &#047; &yen;
                    を除く）を含めても構いません。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="form-section">
            <p class="form-headline">ご確認事項</p>
            <div class="form-row">
              <div class="form-content">
                <div class="form-content-row">
                  利用規約を必ずご一読いただき、内容に同意していただける場合は、【同意する】ボタンにチェックを入れてください。<br />
                  ※なお、新規入会申し込みの際に取得した個人情報の取り扱いにつきましては、利用規約内に規定しております
                </div>
                <div class="form-content-row">
                  <a class="form-policy" href="" target="_blank">
                    利用規約を確認する
                    <i class="icon icon-window form-policy-icon"></i>
                  </a>
                  <label class="check check-policy">
                    <input
                      class="check-input"
                      type="checkbox"
                      value=""
                      name=""
                      v-model="isAgreed"
                    />
                    <span class="check-label">
                      <span class="check-label-text">利用規約に同意する</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="inner-H inner-V">
          <ul class="btnRow">
            <li class="btnRow-item">
              <ActionButton
                class="btn btn-lg btn-main"
                type="button"
                :handle-submit="submit"
                :disabled="!isAgreed"
                button-text="入力内容を確認する"
              />
            </li>
            <li class="btnRow-item">
              <button
                class="btn btn-bd-white"
                type="button"
                @click="historyBack"
              >
                前の画面へ戻る
              </button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>
<script>
import dateOptions from "@/mixins/dateOptions";
import formatDate from "@/mixins/formatDate";
import { CommonConstants } from "@/config/constant";

export default {
  name: "EKYCEntry",
  data: function () {
    return {
      //headerData
      pageName: "新規会員登録",
      genders: CommonConstants.GENDERS,
      isAgreed: false,
      form: {
        nameSei: "",
        nameMei: "",
        kanaSei: "",
        kanaMei: "",
        gender: null,
        zipcode: "",
        telephone: "",
        email: "",
        repeatedEmail: "",
        password: "",
        repeatedPassword: "",
        selectedDay: "",
        selectedMonth: "",
        selectedYear: "",
      },
    };
  },
  watch: {
    selectedDay(newValue) {
      this.form.selectedDay = newValue;
    },
    selectedMonth(newValue) {
      this.form.selectedMonth = newValue;
    },
    selectedYear(newValue) {
      this.form.selectedYear = newValue;
    },
  },
  mixins: [dateOptions, formatDate],
  async mounted() {
    let registeredInfo = this.$store.getters['customer/registInfo'];
    if (registeredInfo.birthday) {
      this.selectedDay = registeredInfo.selectedDay;
      this.selectedMonth = registeredInfo.selectedMonth;
      this.selectedYear = registeredInfo.selectedYear;
    }
    this.form = Object.assign({ ...this.form }, registeredInfo);
    this.form.password = '';
    this.form.repeatedPassword = '';
    this.isAgreed = registeredInfo.isAgreed;
  },
  methods: {
    submit: async function () {
      if (this.isAgreed) {
        this.form.birthday = this.formatDate(
          this.selectedDay,
          this.selectedMonth,
          this.selectedYear,
          "/"
        );
        // eslint-disable-next-line no-unused-vars
        const { password, repeatedPassword, ...registInfo } = this.form;
        registInfo.isAgreed = this.isAgreed;
        this.$store.dispatch('customer/setRegistInfo', this.form);
        sessionStorage.setItem("registInfo", JSON.stringify(registInfo));
        this.$router.push({ name: "EKYCEntryConfirm" });
      }
    },
  },
};
</script>
