const now = new Date();
export default {
  data() {
    return {
      years: [],
      months: [],
      days: [],
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
    };
  },
  mounted() {
    this.getYearOptions();
    this.getMonthOptions();
    this.getDayOptions();
  },
  watch: {
    selectedYear() {
      this.getMonthOptions();
    },
    selectedMonth() {
      this.getDayOptions();
    },
  },
  methods: {
    getYearOptions() {
      const maxYear = 121;
      this.years = [];
      for (let i = now.getFullYear(); i >= now.getFullYear() - maxYear; i--) {
        this.years.push(i);
      }
    },
    getMonthOptions() {
      let monthLength = 12;
      this.months = [];
      if (this.selectedYear == now.getFullYear()) {
        monthLength = now.getMonth() + 1;
      }
      for (let i = 1; i <= monthLength; i++) {
        this.months.push(i);
      }
    },
    getDayOptions() {
      let dayLength = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
      if (this.selectedYear == now.getFullYear() && this.selectedMonth == now.getMonth() + 1) {
        dayLength = now.getDate();
      }
      this.days = [];
      for (let i = 1; i <= dayLength; i++) {
        this.days.push(i);
      }
    },
  },
};
